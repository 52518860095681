import { Editor } from '@tinymce/tinymce-react';
import { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPenToSquare, faHouseChimney, faPen, faCheck, faCross, faXmark, faTrash, faLeftLong } from '@fortawesome/free-solid-svg-icons';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_NEW_AT_ROOT } from '../../constant/constant';
import { notify } from '../toasts/ToastDefault';
import { consoleFxn } from '../../console/console';
import { EDIT_TEXT_AND_SHORT_TEXT } from '../../constant/constant';
import { storage } from '../../firebaseDb/config/firebase';
import { uploadBytes, ref, getDownloadURL } from "firebase/storage";
import uuid from 'react-uuid';
import DeepLinkSection from './DeepLinkSection';
import Swal from 'sweetalert2';
// import tinymce from 'tinymce/tinymce';


const TinyEditorSection = ({ data, index, editValue, handleToggleEditEvent, setEditValue, edit, handleDeleteEvent, editedValue, getEditedValue, linkDestination, type }) => {

    const allData = data?.key;
    const hiddenFileInput = useRef(null);
    const changeEditorbg = useRef(null);
    const [loader, setLoaderValue] = useState(false);
    const [togglEvent, setTogglEvent] = useState((allData?.elementType != '3' ? true : false));
    const [DeepLinkVisiblity, setDeepLinkVisiblityFn] = useState(false);
    const dispatch = useDispatch()
    const [activeEditorIndex, setActiveEditorIndex] = useState(0);
    // on add new form submit 
    const [updatedValue, setAddNewInput] = useState({
        editorBody: allData?.body,
        elementType: allData?.elementType,
        imgUrl: "",

    });

    let name, value;
    const getRecord = (name, value) => {
        setAddNewInput({ ...updatedValue, [name]: value });
        changeEditorbg.current.classList.add("editor-bg");
    }

    const handleUpdateSectionFormSubmit = (e) => {
        e.preventDefault();
        changeEditorbg.current.classList.remove("editor-bg");
        if (togglEvent) {
            if (updatedValue.editorBody.trim() != "") {

                dispatch({ type: EDIT_TEXT_AND_SHORT_TEXT, value: { body: updatedValue.editorBody, elementType: (updatedValue.elementType) ? '1' : '2', imgUrl: "", collapsibleImage: "" }, key: linkDestination + allData.key + "/", propt: ["body", "elementType", "imgUrl", "collapsibleImage"], contentType: type });

            }
            else {

                notify("Data is same", "error");
            }
        } else {
            if (updatedValue.imgUrl != "") {
                const search = image_upload_handler_fun(updatedValue.imgUrl);
                search.then((res) => {
                    dispatch({ type: EDIT_TEXT_AND_SHORT_TEXT, value: { body: "", elementType: '3', imgUrl: res, collapsibleImage: (updatedValue.elementType) ? true : false }, key: linkDestination + allData.key + "/", propt: ["body", "elementType", "imgUrl", "collapsibleImage"], contentType: type });
                    hiddenFileInput.current.value = "";
                }).catch((error) => {

                    notify("Image Upload Error ! \n" + error, "error");
                });

            } else {

                dispatch({ type: EDIT_TEXT_AND_SHORT_TEXT, value: { body: "", elementType: '3', collapsibleImage: (updatedValue.elementType) ? true : false }, key: linkDestination + allData.key + "/", propt: ["body", "elementType", "collapsibleImage"], contentType: type });
            }
        }

    }


    setTimeout(() => {

        setLoaderValue(true);

    }, 1000);

    // image upload handler function 
    const image_upload_handler_fun = (blobInfo, progress) => new Promise((resolve, reject) => {

        const file = (typeof blobInfo.blob === "function") ? blobInfo.blob() : blobInfo;

        const storageRef = ref(storage, '/test_images/' + uuid() + "_" + file.name);


        uploadBytes(storageRef, file).then((snapshot) => {

            getDownloadURL(snapshot.ref).then((downloadURL) => {

                resolve(downloadURL);

            }).catch((error) => {

                reject("Error getting download URL:", error);
                // failure(error);
            });
        }).catch((error) => {

            reject("Error uploading image:", error);
        });


        // resolve('/uploaded/image/path/image.png');

    });

    let DeepLinkData = useSelector(state => state.AllData);

    const toggleDeepLinksectionVisiblity = (index) => {
        if (DeepLinkData.editorContent) {
            setDeepLinkVisiblityFn(!DeepLinkVisiblity);
            setActiveEditorIndex(index)
        } else {
            Swal.fire({
                title: "Permission Denied!",
                text: "You do not have full content editor access, to create deep links, please contact your administrator for full access.",
                icon: "error",

            });
        }


    }

    return (
        <>
            {/* model to display data  */}
            <DeepLinkSection show={DeepLinkVisiblity} onHide={() => setDeepLinkVisiblityFn(false)} data={DeepLinkData.editorContent} toggleDeepLinksectionVisiblity={toggleDeepLinksectionVisiblity} activeEditorIndex={activeEditorIndex} />

            <div className="col-12 list list__item is-idle js-item" data-check-all="checkAll" data-id={linkDestination}>
                <div className="card d-flex flex-row mb-3 ">
                    <div className=" flex-grow-1 min-width-zero">
                        <div className="card-body align-self-center flex-column flex-md-row justify-content-between min-width-zero align-items-md-center">
                            <div className='row list-item-heading pb-3'>
                                <div className='col-md-8' id={allData?.key}>
                                    {
                                        (editValue.index == index) ?
                                            <>
                                                <input type="text" style={{ width: "20rem" }} data-key={linkDestination + allData.key + "/"} onChange={(e) => getEditedValue(e)} value={editedValue.value} />

                                                <FontAwesomeIcon style={{ cursor: 'pointer' }} onClick={() => setEditValue({})} className='ms-3 text-primary' icon={faXmark} />

                                                <FontAwesomeIcon style={{ cursor: 'pointer' }} className='ms-3 text-primary' onClick={() => edit({ propt: ["title"] })}
                                                    icon={faCheck} />
                                            </>
                                            :
                                            <>
                                                {(allData.title) ? allData.title : "No Name"}
                                                <FontAwesomeIcon style={{ cursor: 'pointer' }} onClick={() => handleToggleEditEvent(index, allData.title)} className='ms-3 text-primary' icon={faPen} />

                                                <FontAwesomeIcon style={{ cursor: 'pointer' }} onClick={() => handleDeleteEvent(linkDestination + allData.key + "/", allData.title)} className='ms-3 text-primary' icon={faTrash} />

                                            </>
                                    }

                                </div>
                                <div className='col-md-3 text-end'>
                                    <div className="form-group">
                                        <div className={`btn btn-${(togglEvent) ? "primary" : "info"}`} onClick={() => setTogglEvent(!togglEvent)}>
                                            {(togglEvent) ? "Change To Image" : "Change To Editor"}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <form onSubmit={(e) => handleUpdateSectionFormSubmit(e)} >
                                <nav className="breadcrumb-container d-none d-sm-block d-lg-inline-block bg-light  mx-2 p-4 rounded-2 w-100" aria-label="breadcrumb"
                                    ref={changeEditorbg}>
                                    {
                                        (togglEvent) ?

                                            loader ?

                                                <Editor
                                                    apiKey='2bzh0pg8ngu6v6bwkpvr6np02lcgot98t9vd1itsinguq92r'
                                                    onEditorChange={(newValue, editor) => {
                                                        if (editor.isDirty()) {

                                                            getRecord("editorBody", newValue, editor)
                                                        }
                                                    }}
                                                    initialValue={allData?.body}
                                                    id={"editor_" + index}
                                                    init={{
                                                        key: index,
                                                        height: 500,
                                                        // selector: "#editor_" + index,
                                                        images_upload_handler: image_upload_handler_fun,
                                                        menubar: false,
                                                        toolbar_mode: 'scrolling',
                                                        plugins: ["anchor", "autolink", "autoresize", "codesample", "spellchecker", "paste", "image", "file", "imagetools", "lists", "link", "media", "noneditable", "table", "template"],
                                                        toolbar: "urldialog  undo redo | fontselect | fontsizeselect | styleselect | bold italic underline strikethrough | justifyleft justifycenter justifyright justifyfull | bullist numlist | outdent indent hr |  link unlink | image | file | sub sup | forecolor backcolor forecolorpicker backcolorpicker|  removeformat formatselect | tablecontrols table row_props cell_props delete_col delete_row col_after col_before row_after row_before split_cells merge_cells | indigoDeepLink|fontsize",
                                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; overflow-y:scroll !important; }',
                                                        setup: (editor) => {

                                                            editor.ui.registry.addButton('urldialog', {
                                                                icon: 'browse',
                                                                text: 'Deep Link',
                                                                onAction: () => {
                                                                    // show Deep Link section on click
                                                                    toggleDeepLinksectionVisiblity(index)

                                                                }
                                                            });
                                                            //    window event here
                                                            window.addEventListener("message", (event) => {
                                                                if (event.data.cmd == "insertDeepLink_" + index) {
                                                                    let name = event.data.value.name;
                                                                    let pageType = event.data.value.pageType;
                                                                    let linkDestination = event.data.value.linkDestination;
                                                                    editor.insertContent(`[deeplink]${pageType}|${linkDestination}|${name}[/deeplink]`);
                                                                }
                                                            });


                                                        },

                                                    }}
                                                />
                                                :
                                                <span>Loading Editor...</span>
                                            :

                                            <>
                                                <div className="card mb-3" style={{ width: "18rem" }}>
                                                    <input type="file" className="form-control-file" ref={hiddenFileInput} onChange={(e) => getRecord("imgUrl", e.target.files[0])} />
                                                </div>
                                                {(allData.imgURL != undefined && allData.imgURL != "")
                                                    ?
                                                    <img src={allData.imgURL} className='img-thumbnail rounded-3' style={{ width: "18rem" }} alt="some Image" />
                                                    :
                                                    ""
                                                }
                                            </>

                                    }

                                </nav>
                                <div >
                                    <div className="row align-items-center">
                                        <div className="col-6 ">
                                            <div className="form-group form-check ms-4">
                                                <input style={{ fontSize: "1.2rem" }} className="form-check-input " onChange={(e) => getRecord("elementType", e.target.checked)} type="checkbox" name='isCollapsible' defaultChecked={allData?.collapsibleImage || allData?.elementType == '1'} />
                                                <label style={{ fontSize: "1rem" }} className="form-check-label" htmlFor="defaultCheck1">
                                                    Collapsible {allData?.collapsibleImage}
                                                </label>
                                            </div>
                                        </div>
                                        <div className='text-end col-6'>
                                            <Button variant="primary" className='mt-3' type="submit">
                                                Save Changes
                                            </Button>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>


            {/* <button onClick={log}>Log editor content</button> */}
        </>
    )

}
export default TinyEditorSection