import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPenToSquare, faHouseChimney, faPen, faCheck, faCross, faXmark, faTrash, faLeftLong, faCirclePlus, faListOl, faMagnifyingGlass, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { useState, useRef } from 'react';
import { ContentPage, EditContentElementPage, ListPage, EDIT_TEXT_AND_SHORT_TEXT, DELETE_SECTION, SET_ORDER_PAGE, QBankCategory, QBankQuestion, EDIT_RECORDS } from '../../constant/constant';
import { useNavigate, useLocation } from "react-router-dom";
import TinyEditorSection from './TinyEditorSection';
import loadingImage from '../../assets/images/loading2.gif'
import { useDispatch, useSelector } from 'react-redux';
import { setEditedStatus, deleteData, errorInOperation } from '../../redux/features/fetchAllRecord';
import ToastDefault, { notify } from '../toasts/ToastDefault';
import AddNewRightSideModel from './AddNewRightSideModel';
import Swal from 'sweetalert2';
import { recursionData, reduxSearchData } from '../../commanFunction';
import AddNewTopModel from './AddNewTopModel';
import Accordion from 'react-bootstrap/Accordion';
import OrderListComponet from './OrderListComponet';
import useFetch from '../../global/callMasterData';

function QBank({ error, userId }) {

    const dispatch = useDispatch()

    const navigate = useNavigate();

    const location = useLocation();

    const serachbarRef = useRef("");

    const [orderListView, setOrderListView] = useState(false);

    const [keywords, setKeywords] = useState("");

    // let reduxData = useSelector(state => state.AllData);

    let allReduxData = useSelector(state => state.AllData);

    let [reduxData, setReduxData] = useState(allReduxData);

    // if(allReduxData.length == 0 || allReduxData.policies == undefined){

    // }
    let reduxDataCrudAction = useSelector(state => state);

    let [searchData, setSearchData] = useState(reduxData);
    let [userKey, setUserKey] = useState("");

    const [searchDataChild, setSearchDataChild] = useState([]);

    // loading button use state for submiting a new data
    const [isLoading, setLoading] = useState(false);

    // navigate link destination use state
    const [linkDestination, setLinkDestination] = useState("");

    // for reorder data manage state 
    const [reorderData, setReorderData] = useState([]);

    // manage status state 
    useEffect(() => {

        if (reduxDataCrudAction.crudAction != "" || reduxDataCrudAction.error != null) {

            if (reduxDataCrudAction.crudAction.type == "edit") {

                notify(" Data Updated Successfully!");

                setReorderData([]);
                // notify(" Updated to '" + reduxDataCrudAction.crudAction.value + "'");

            } else if (reduxDataCrudAction.crudAction.type == "delete") {
                // notify(" Deleted '" + reduxDataCrudAction.crudAction.value + "'", "error");
                Swal.fire({
                    title: "Deleted!",
                    text: "\"" + reduxDataCrudAction.crudAction.value + "\" has been deleted.",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 2500
                });
            } else if (reduxDataCrudAction.crudAction.type == "addNew") {

                if (reduxDataCrudAction.crudAction.navPush == EditContentElementPage) {
                    notify("New Section '" + reduxDataCrudAction.crudAction.value + "' Added!", "success");
                    addNewTopModel();
                } else {
                    notify("New Value '" + reduxDataCrudAction.crudAction.value + "' Added!", "success");
                    toggleModelVisibility();
                    setLoading(false);
                }


            } else if (reduxDataCrudAction.error != "") {

                notify(reduxDataCrudAction.error, "error");
                dispatch(errorInOperation(null));
                setLoading(false);

            }
            dispatch(setEditedStatus(""));
        }


    }, [reduxDataCrudAction])

    // handle toggale edit event 
    const [editValue, setEditValue] = useState({});
    const handleToggleEditEvent = (index, editValue = "") => {

        setEditedValue({ value: editValue, key: "" })
        setEditValue({ index: index })


    }
    // edited value with inplace editing 
    const [editedValue, setEditedValue] = useState({
        value: "",
        key: ""
    });

    let value, key;
    const getEditedValue = (e) => {
        value = e.target.value;
        key = e.target.getAttribute("data-key");
        setEditedValue({
            value: value,
            key: key
        })

    }

    function edit({ propt }) {

        if (editedValue.key != "" && editedValue.value.trim() != "") {

            if (propt.length > 0 && propt[0] == "title") {

                dispatch({ type: EDIT_TEXT_AND_SHORT_TEXT, value: { 'title': editedValue.value }, key: editedValue.key, propt, contentType: "Q_Bank" });

            } else {

                dispatch({ type: EDIT_TEXT_AND_SHORT_TEXT, value: { 'text': editedValue.value, 'shortText': editedValue.value }, key: editedValue.key, propt, contentType: "policy" });

            }
            setEditedValue({
                value: "",
                key: ""
            });
            setEditValue({});

        }

        else {

            notify("No Changes Made To Save!", "error");
        }

    }
    /////////// close editing section ///////////////

    // manage delete a specific delete 
    function handleDeleteEvent(key, deleteValue) {
        Swal.fire({
            title: "Are you sure you want to delete \"" + deleteValue + "\" ?",
            text: "All chiled node of \"" + deleteValue + "\" will also be deleted",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch({ type: DELETE_SECTION, key: key, value: deleteValue })
                // dispatch(deleteData({ key: key, value: deleteValue }))
            }
        });


    }

    // toggle add new model section 
    const [isModelVisible, setIsModelVisible] = useState(false);

    const toggleModelVisibility = (destination) => {

        // setIsModelVisible(!isModelVisible);
        setLinkDestination((destination != undefined) ? destination : "/q_bank/");
        setIsModelVisible(!isModelVisible);
    };

    // -----------------navigate to page with key--------------------
    function navigateWithKey(key, navigationType = "") {
        // setSearchData({});
        if (navigationType != "" && navigationType == "bySearch") {

            serachbarRef.current.value = "";

            navigate("/", { state: { key, type: "bySearch" } });


        } else {

            navigate("/Q-Bank-Section", { state: { key } });

        }
        setEditValue({});
        setEditedValue({
            value: "",
            key: ""
        });
        setOrderListView(false)
        setIsModelVisible(false);
        setIsTopModelVisible(false);


    }

    function navigateByDireact(type = "home") {
        if (type == "back") {
            navigate(-1)
        } else {
            navigate("/Q-Bank");
        }
        setEditValue({});
        setEditedValue({
            value: "",
            key: ""
        });
        setOrderListView(false)
        setIsModelVisible(false);
        setIsTopModelVisible(false);
    }
    // -----------------------------------------------//

    useEffect(() => {

        if (location.state != null) {

            const keyToFind = location.state.key;

            let searchDataType = "onlyKey";

            if (location.state.type == "bySearch") {

                searchDataType = reduxDataCrudAction.rootLevelKey.find((element) => element == keyToFind) == undefined ? "onlyKey" : "";
            }

            //for redirect too root level if search in root level
            let recursionDataResponse = recursionData(allReduxData, keyToFind, searchDataType);

            if (Object.entries(recursionDataResponse).length > 0) {
                // reduxData = recursionDataResponse;
                setReduxData(recursionDataResponse);
                setSearchData(recursionDataResponse);
                // searchData = recursionDataResponse;

            } else if (location.state.type != "bySearch") {
                navigate(-1);
            }

        } else {
            setReduxData(allReduxData);
            setSearchData(allReduxData);
        }

    }, [location, allReduxData])
    // ----------------top model visable -------------------//
    const [isTopModelVisible, setIsTopModelVisible] = useState(false);
    const addNewTopModel = (destination) => {
        setLinkDestination((destination != undefined) ? destination : "/policies/documents/" + userKey + "/");
        setIsTopModelVisible(!isTopModelVisible)
    }

    // -----------------navigate to smooth scroll --------------------
    function scrollToSection(sectionId) {
        var section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth', block: 'center' });
            if (document.getElementsByClassName('scrollActiveClass').length > 0) {
                Array.from(document.getElementsByClassName('scrollActiveClass')).forEach(element => {
                    element.classList.remove('scrollActiveClass');
                });
            }
            document.getElementById('sideNavId_' + sectionId).classList.add('scrollActiveClass');
            section.parentElement.parentElement.classList.add('highlightOnScroll');
            setTimeout(function () {
                section.parentElement.parentElement.classList.remove('highlightOnScroll');

            }, 3000);
        } else {

            navigateWithKey(sectionId, "bySearch");
        }
    }

    // -----------------search bar event --------------------
    function setSearch(searchValue) {

        if (searchValue.length > 0) {

            let recursionDataResponse = reduxSearchData(allReduxData?.Q_Bank, searchValue)
            // console.log(recursionDataResponse, "recursionDataResponse");
            if (Object.entries(recursionDataResponse).length > 0) {

                // for sorting in alphabetical order
                setSearchData(recursionDataResponse.sort((a, b) => a.text.localeCompare(b.text)));


            } else {
                setSearchData({})
            }
        } else {

            setSearchData(reduxData)
        }

    }

    // handle keyword update 
    function handleKeyWordsUpdate(linkDestination) {
        if (keywords.trim() != "") {
            dispatch({ type: EDIT_TEXT_AND_SHORT_TEXT, value: { 'keywords': keywords }, key: linkDestination, propt: ["keywords"] });
            setKeywords("");
        } else {
            notify("Please enter some new keywords !", 'error');
        }

    }

    // save reorder list to firebase and redux state 
    function saveReorderList() {
        if (reorderData.length > 0) {
            // console.log(reorderData, "reorderData")
            dispatch({ type: SET_ORDER_PAGE, data: reorderData });
        }
        setOrderListView(false);
    }

    // --------------------///////
    useEffect(() => {

        if (allReduxData && Object.keys(allReduxData).length > 0) {
            let UserKey = (allReduxData?.policies?.users[userId.uid] != null) ? Object.keys(allReduxData.policies.users[userId.uid].amionLogin)[0] : ""
            if (UserKey != "") {
                let documentKey = (allReduxData.policies.institutions.length > 0) ? allReduxData.policies.institutions.find(element => element?.amionLogin == UserKey) : UserKey;
                setUserKey((documentKey?.documentKey) ? documentKey.documentKey : UserKey)
            }
        }



    }, [reduxData])


    // function to handle publish all sesction-----------//
    function publishAll(linkDestination, key) {
        let data = (Object.values(allReduxData?.Q_Bank[key]) && Object.values(allReduxData?.Q_Bank[key]).filter(element => element.navPush === QBankQuestion)) || [];
        if (data && data.length > 0) {
            let propt = data.map(element => [element.key + "/publishStatus"]);
            const value = {};
            data.map(element => (value[element.key + "/publishStatus"] = true));
            dispatch({ type: EDIT_TEXT_AND_SHORT_TEXT, value: value, key: linkDestination, propt, contentType: QBankQuestion });

        }
    }
    return (
        <>
            <ToastDefault />
            {isTopModelVisible && <AddNewTopModel modelVisible={addNewTopModel} linkKey={linkDestination} totalItemsLength={Object.values((reduxData?.policies?.documents?.[userKey]) ? reduxData.policies.documents[userKey] : reduxData).filter(element => element.navPush === EditContentElementPage).length} type={"policy"} />}

            {isModelVisible && <AddNewRightSideModel linkKey={linkDestination} toggleModelVisibility={toggleModelVisibility} loading={{ isLoading, setLoading }} totalItemsLength={Object.values((reduxData?.Q_Bank) ? reduxData.Q_Bank : reduxData).filter(element => element.navPush === QBankCategory)?.length} type={QBankCategory} />}

            {
                (!error) ?

                    <div className=" d-flex justify-content-center align-items-center" style={{ "height": "90vh" }}>

                        <img src={require('../../assets/images/loading2.gif')} ></img>

                    </div>
                    :
                    <>
                        {
                            (reduxData?.Q_Bank && Object.keys(reduxData).length > 0) ?
                                <div className="container-fluid disable-text-selection" style={{ paddingLeft: "130px", "marginTop": "8rem" }} >
                                    <div className="row ">
                                        {/* top home section  */}
                                        <div className='col-md-3 ps-5'>
                                            <h1 onClick={() => { navigateByDireact("home") }}><FontAwesomeIcon className='text-primary' icon={faHouseChimney} /></h1>

                                            {(location.state != null) ?
                                                <button type="button" className="btn btn-primary ms-3" onClick={() => { navigateByDireact("back") }}>
                                                    <FontAwesomeIcon icon={faLeftLong} />
                                                    &nbsp;  Back</button>

                                                : ""
                                            }

                                        </div>
                                        <div className="col-5 ps-5">
                                        </div>
                                        {allReduxData?.Q_Bank &&
                                            <div className={`col-md-4 pe-5 d-flex align-items-center justify-content-${orderListView ? 'end' : 'between'}`}>
                                                {
                                                    <>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='bg-info mx-2 rounded-1' style={{ width: '1rem', height: '1rem', display: 'inline-block' }}></div>
                                                            <span className='text-info fw-bold' >Publish</span>
                                                            <div className='bg-primary ms-5 me-2 rounded-1' style={{ width: '1rem', height: '1rem', display: 'inline-block' }}></div>
                                                            <span className='text-primary fw-bold'>UnPublish</span>
                                                        </div>

                                                        <div className="top-right-button-container">
                                                            {
                                                                (reduxData.navPush === ContentPage) ?
                                                                    <button type="button" className="btn btn-primary  top-right-button mr-1" onClick={() => addNewTopModel(reduxData.linkDestination)} >
                                                                        <FontAwesomeIcon icon={faPlus} /> ADD Section </button>
                                                                    :

                                                                    <button type="button" className="btn btn-primary  top-right-button mr-1" onClick={() => toggleModelVisibility(reduxData?.Q_Bank?.linkDestination)} >

                                                                        <FontAwesomeIcon icon={faPlus} />ADD NEW</button>
                                                            }
                                                        </div>
                                                    </>
                                                }
                                            </div>}
                                        {/* top home section end */}
                                        {/* upper top main section  */}
                                        {allReduxData?.Q_Bank?.title &&
                                            <>
                                                <div className="col-9 offset-3 list" data-check-all="checkAll">
                                                    <div className="card d-flex flex-row mb-3 ">
                                                        <div className=" flex-grow-1 min-width-zero">
                                                            <div className="card-body align-self-center flex-column flex-md-row justify-content-between min-width-zero align-items-md-center">
                                                                <div className='row list-item-heading pb-3'>
                                                                    <>
                                                                        <div className='col-md-8 '>

                                                                            {
                                                                                (editValue.index == "topValue") ?
                                                                                    <>

                                                                                        <input type="text" style={{ width: "20rem" }}

                                                                                            data-key={(reduxData?.Q_Bank?.linkDestination) ? reduxData.Q_Bank.linkDestination : ""}

                                                                                            onChange={(e) =>

                                                                                                getEditedValue(e)} value={editedValue.value}
                                                                                        />

                                                                                        <FontAwesomeIcon style={{ cursor: 'pointer' }} onClick={() => setEditValue({})} className='ms-3 text-primary' icon={faXmark} />

                                                                                        <FontAwesomeIcon style={{ cursor: 'pointer' }} className='ms-3 text-primary' onClick={() => edit({ propt: ["title"] })} icon={faCheck} />
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <span className='display-6'>
                                                                                            {(reduxData?.Q_Bank?.title) ? reduxData.Q_Bank.title : "No Name"}
                                                                                        </span>

                                                                                        <FontAwesomeIcon style={{ cursor: 'pointer' }} onClick={() => handleToggleEditEvent("topValue", (reduxData?.Q_Bank?.title) ? reduxData.Q_Bank.title : "No Name")} className='ms-3 text-primary' icon={faPen} />


                                                                                        {/* <FontAwesomeIcon style={{ cursor: 'pointer' }}
                                                                                            onClick={() => handleDeleteEvent((reduxData?.Q_Bank?.linkDestination) ? (reduxData.Q_Bank.linkDestination) : "", (reduxData?.Q_Bank?.title) ? reduxData.Q_Bank.title : "")

                                                                                            }
                                                                                            className='ms-3 text-primary'
                                                                                            icon={faTrash}
                                                                                        /> */}

                                                                                    </>

                                                                            }
                                                                        </div>

                                                                    </>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12  my-2" >
                                                    {(reduxData.navPush === ContentPage) ?
                                                        <div className="col-9 bg-white offset-3 p-2" >
                                                            <div className='h5 p-3'>Keywords <small>(Seperate keywords by spaces, then click update button.)</small></div>
                                                            {/* <span className='h5 p-3 m-3'>Keywords</span> */}
                                                            <nav className="breadcrumb-container d-none d-sm-block d-lg-inline-block bg-light mx-2 p-4 rounded-2 w-100" aria-label="breadcrumb" style={{ border: "0.1rem solid #dee2e6" }}>
                                                                <div className="form-group">
                                                                    <textarea className="form-control" rows="3" onChange={(e) => { setKeywords(e.target.value) }}>
                                                                        {reduxData.keywords && reduxData.keywords}
                                                                    </textarea>
                                                                </div>
                                                                <div className="btn btn-primary float-end mt-3" onClick={() => { handleKeyWordsUpdate(reduxData.linkDestination) }}>Update</div>
                                                            </nav>
                                                        </div>
                                                        : ""}
                                                </div>
                                            </>
                                        }
                                        {/* upper top main section end */}
                                        {/* side link nav bar start */}
                                        <div className="col-3 " >
                                            <div className="card position-fixed leftSideScrollBar" style={{ left: "130px", width: "25rem", height: "60vh", overflowY: "auto", scrollbarWidth: "none", top: "12rem" }}>
                                                <div className="card-body">
                                                    <div className="" style={{ padding: "0px 0rem 0px 1.8rem" }}>
                                                        <div style={{
                                                            border: "1px solid #d7d7d7",
                                                            borderRadius: "5px",
                                                            background: " #f8f8f8",

                                                        }}>
                                                            <input id="searchBar" ref={serachbarRef} placeholder="Search..." style={{
                                                                border: "initial",
                                                                outline: "none",
                                                                background: "0 0",
                                                                // outline: "initial !important",
                                                                fontSize: ".8rem",
                                                                width: "93%",
                                                                color: "#3a3a3a",
                                                                padding: "0.4rem 1rem",
                                                                lineHeight: "2",

                                                            }}
                                                                onChange={(e) => setSearch(e.target.value)}
                                                            />
                                                            <span className="search-icon"
                                                                style={{
                                                                    fontSize: "17px",
                                                                    borderRadius: "10px",
                                                                    color: "#d7d7d7",
                                                                    width: "40px",
                                                                    height: "40px",
                                                                    right: "3px",
                                                                    textAlign: "center",
                                                                    cursor: "pointer"
                                                                }}>
                                                                <FontAwesomeIcon icon={faMagnifyingGlass} />
                                                            </span>
                                                        </div>
                                                        {/* <input className="form-control w-75" type="search" onChange={(e) => setSearch(e.target.value)} placeholder="Search" aria-label="Search" /> */}
                                                    </div>
                                                    <ul >
                                                        {

                                                            allReduxData?.Q_Bank
                                                                ?
                                                                Object.values(((Object.entries(searchData).length > 0) ? ((searchData?.Q_Bank) ? searchData?.Q_Bank : searchData) : reduxData?.Q_Bank)).toSorted((a, b) => a.orderInPage - b.orderInPage).map((key, index) => {

                                                                    if (key.navPush === QBankCategory) {
                                                                        return (
                                                                            <>
                                                                                <li className="actifve p-2 border-bottom my-3 " id={'sideNavId_' + key.key} style={{ listStyleType: "none", fontSize: "1.1rem" }} key={"sidebar_" + index}>
                                                                                    <div className=' d-flex justify-content-between ' onClick={() => scrollToSection(key.key)} style={{ cursor: "pointer" }}>
                                                                                        {(key.text != undefined) ? key.text : "No Name"}
                                                                                        <FontAwesomeIcon icon={faChevronRight} opacity={0.5} />
                                                                                    </div>

                                                                                    {(searchDataChild.length > 0) ? searchDataChild.map((key, index) => {
                                                                                        <div style={{ fontSize: "0.78rem" }}>
                                                                                            {key.text}
                                                                                        </div>

                                                                                    }) : ""}

                                                                                </li>
                                                                            </>
                                                                        )
                                                                    } else if (key.navPush === EditContentElementPage) {
                                                                        return (
                                                                            <>
                                                                                {(key.elementType == '2' || (key.elementType == '3' && !key.collapsibleImage)) ?
                                                                                    <div className="card my-3" key={index}>
                                                                                        <div className="card-header  py-3" id={"accordionId_" + key.key} style={{ background: "#ebe6ff", fontSize: "1rem" }}>
                                                                                            {(key.title != "") ? key.title : "No Name"}
                                                                                        </div>
                                                                                        <div className="card-body border accordion-body" id={'sideNavId_' + key.key} onClick={() => scrollToSection(key.key)} dangerouslySetInnerHTML={{ __html: (key.elementType == '3') ? ` <img src=${key.imgUrl} className='img-thumbnail rounded-3' style= 'width:100%'  alt="some Image" />` : key.body }}  >
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    (key.elementType == '1' || (key.elementType == '3' && key.collapsibleImage)) ?
                                                                                        <Accordion className='my-3' >
                                                                                            <Accordion.Item eventKey={key.key} >
                                                                                                <Accordion.Header id={"accordionId_" + key.key} > {(key.title != "") ? key.title : "No Name"}</Accordion.Header>
                                                                                                <Accordion.Body id={'sideNavId_' + key.key} onClick={() => scrollToSection(key.key)} dangerouslySetInnerHTML={{ __html: (key.elementType == '3') ? ` <img src=${key.imgUrl} className='img-thumbnail rounded-3' style= 'width:100%' alt="some Image" />` : key.body }}   >
                                                                                                </Accordion.Body>
                                                                                            </Accordion.Item>
                                                                                        </Accordion>
                                                                                        :

                                                                                        ""

                                                                                }
                                                                            </>
                                                                        )
                                                                    }
                                                                })
                                                                :

                                                                <li className="actifve p-2 border-bottom my-3 " id={'sideNavId_1'} style={{ listStyleType: "none", fontSize: "1.1rem" }} key={"sidebar_1"}>
                                                                    <div className=' d-flex justify-content-between '>
                                                                        {"No Data To Display"}
                                                                        <FontAwesomeIcon icon={faChevronRight} opacity={0.5} />
                                                                    </div>

                                                                </li>
                                                        }

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        {/* side link nav bar end */}
                                        {/* main section */}

                                        <div className="col-9 ">
                                            {(allReduxData?.Q_Bank) ?

                                                Object.values((reduxData?.Q_Bank) ? reduxData?.Q_Bank : reduxData).map((key, index) => {
                                                    if (key.navPush === QBankCategory) {
                                                        return (
                                                            <>
                                                                <div className="col-12   " data-check-all="checkAll" data-id={key.linkDestination} key={key.key}>
                                                                    <div className="card d-flex flex-row mb-3 ">
                                                                        <div className=" flex-grow-1 min-width-zero">
                                                                            <div className="card-body align-self-center flex-column flex-md-row justify-content-between min-width-zero align-items-md-center">
                                                                                <div className='row list-item-heading pb-3 align-items-center'>
                                                                                    <div className='col-md-8' id={key.key}>
                                                                                        {
                                                                                            (editValue.index == index) ?
                                                                                                <>

                                                                                                    <input type="text" style={{ width: "20rem" }} data-key={key.linkDestination} onChange={(e) => getEditedValue(e)} value={editedValue.value} />

                                                                                                    <FontAwesomeIcon style={{ cursor: 'pointer' }} onClick={() => setEditValue({})} className='ms-3 text-primary' icon={faXmark} />

                                                                                                    <FontAwesomeIcon style={{ cursor: 'pointer' }} className='ms-3 text-primary' onClick={() => edit({ propt: ["text", "shortText"] })} icon={faCheck} />
                                                                                                </>

                                                                                                :

                                                                                                <>
                                                                                                    {key.text}
                                                                                                    <FontAwesomeIcon style={{ cursor: 'pointer' }} onClick={() => handleToggleEditEvent(index, key.text)} className='ms-3 text-primary' icon={faPen} />

                                                                                                    <FontAwesomeIcon style={{ cursor: 'pointer' }} onClick={() => handleDeleteEvent(key.linkDestination, key.text)} className='ms-3 text-primary' icon={faTrash} />

                                                                                                </>

                                                                                        }

                                                                                    </div>
                                                                                    {/* display the publish all button */}
                                                                                    {
                                                                                        Object.values(key).filter(element => element?.navPush === QBankQuestion).length > 0 &&
                                                                                        <div className='col-md-4'>

                                                                                            <button type='button' className={`btn btn-${Object.values(key).filter(element => element?.publishStatus === false).length <= 0 ? "primary" : "success"} float-end`}
                                                                                                disabled={Object.values(key).filter(element => element?.publishStatus === false).length <= 0 ? true : false}

                                                                                                onClick={() => (publishAll(key?.linkDestination, key?.key))}>

                                                                                                Publish All
                                                                                            </button>
                                                                                        </div>
                                                                                    }
                                                                                    {/* drag and drop handle are here */}
                                                                                </div>

                                                                                <nav className="breadcrumb-container d-none d-sm-block d-lg-inline-block bg-light mx-2 p-4 rounded-2 w-100" aria-label="breadcrumb" style={{ border: "0.1rem solid #dee2e6" }}>

                                                                                    <ol className="breadcrumb pt-0">
                                                                                        {
                                                                                            Object.values(key).toSorted((a, b) => a.orderInPage - b.orderInPage).map((innerkey, index) => {
                                                                                                if (innerkey.navPush == QBankQuestion && innerkey.subject != undefined) {
                                                                                                    return (
                                                                                                        <li key={"breadcrumb_" + index} className="breadcrumb-item pb-2">
                                                                                                            <button type="button"
                                                                                                                className={`btn btn-outline-${innerkey?.publishStatus == true ? 'info' : 'primary'} mb-1 btn-xs`}
                                                                                                                onClick={() => { navigate('/Q-Bank-Section', { state: { key: key.key, type: "ByQBank", hitKey: innerkey.key } }) }}>
                                                                                                                <b>{(innerkey?.subject) ? innerkey?.subject : "No name"} </b>
                                                                                                            </button>
                                                                                                        </li>

                                                                                                    )

                                                                                                }

                                                                                            })
                                                                                        }
                                                                                        {(key.navPush == QBankCategory) &&
                                                                                            <>
                                                                                                <div className='mt-1'>
                                                                                                    <FontAwesomeIcon icon={faCirclePlus} size="xl" style={{ cursor: 'pointer' }} onClick={() => { navigate('/Q-Bank-Section', { state: { key: key.key, type: "ByQBank" } }) }} className='ms-2 text-primary' />
                                                                                                </div>
                                                                                            </>
                                                                                        }


                                                                                    </ol>
                                                                                </nav>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>

                                                        );
                                                    }
                                                    else {
                                                        return (<>
                                                            {index == 0 && <div className='text-center bg-white p-5 h3'>No Data To Display</div>}
                                                        </>)
                                                    }

                                                    // else if (key.navPush === EditContentElementPage) {
                                                    //     return <TinyEditorSection data={{ key }} linkDestination={reduxData.linkDestination} index={index} editValue={editValue} handleToggleEditEvent={handleToggleEditEvent} setEditValue={setEditValue} edit={edit} handleDeleteEvent={handleDeleteEvent} editedValue={editedValue} getEditedValue={getEditedValue} type={"policy"} />;
                                                    // }
                                                })
                                                :
                                                ""
                                            }
                                        </div>
                                        {/* main section end */}

                                    </div>
                                </div>
                                :

                                <div className=" d-flex justify-content-center align-items-center" style={{ "height": "90vh" }}>
                                    <img src={require('../../assets/images/loading2.gif')} ></img>

                                </div>
                        }
                    </>
            }

        </>
    )
}

export default QBank