import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import { update_data, delete_section, add_new_data, setOrderPageOpration, update_records } from "../firebaseDb/firebaseCrudOpration/firebasecrud";
import { add_new_user, delete_user, update_user, forgotPasswordOtpSentMethod, verifyForgotPasswordOtp, updatePasswordInAuth } from '../firebaseDb/firebaseAuthenticationOperation/firebaseAuthenticationOperation';
import { editData, errorInOperation, deleteData, addNewData, forgotPasswordOtpSent, verifyOtp, clearStep, userPasswordUpdateStatus, AiGenratedQuestionChoice } from "../redux/features/fetchAllRecord";
import { ADD_NEW_AT_ROOT, DELETE_SECTION, EDIT_TEXT_AND_SHORT_TEXT, SET_ORDER_PAGE, ADD_NEW_USER, DELETE_USER, UPDATE_USER_INFO, EDIT_QBANK_QUESTION, FORGOT_PASSWORD_OTP, GET_GPT_RESPONSE } from '../constant/constant';
import { captureException } from '@sentry/react';
import axios from 'axios';


// edit data text and short text
function* edit(data) {

    const editValue = data.value;
    const editKey = data.key;
    const editPropt = data.propt;
    try {

        const response = yield update_data(editValue, editKey, editPropt, data);

        if (response == true) {

            yield put(editData(data));

        } else {

            yield put(errorInOperation("There is An Error in Edit value!"));
        }

    } catch (error) {
        captureException(error, "error");
        yield put(errorInOperation("There is An Error in Edit value!"));

    }

}
// delete a whole section
function* deleteSection(data) {

    const deleteKey = data.key;

    try {

        const response = yield delete_section(deleteKey);

        if (response == true) {
            yield put(deleteData(data));
        } else {
            yield put(errorInOperation("There is An Error in Delete value!"));
        }


    } catch (error) {
        captureException(error, "error");
        yield put(errorInOperation("There is An Error in Delete value!"));

    }


}
// add new at root level
function* addNewAtRoot(data) {

    const linkDestination = data.linkDestination;
    try {
        const response = yield add_new_data(data, linkDestination);

        if (response == true) {

            yield put(addNewData(data));

        } else {

            yield put(errorInOperation("There is An Error in Add New value!"));
        }

    } catch (error) {
        captureException(error, "error");
        yield put(errorInOperation("There is An Error in Add New value!"));

    }


}

function* setOrderPage(data) {

    try {
        const response = yield setOrderPageOpration(data.data);

        if (response == true) {

            yield put(editData({ value: "Order Page", propt: ["orderPage"] }));

        } else {

            yield put(errorInOperation("There is An Error in Set Order Page!"));
        }

    } catch (error) {
        captureException(error, "error");
        yield put(errorInOperation("There is An Error in Set Order Page!"));

    }

}

function* addNewUser(data) {
    try {
        const response = yield add_new_user(data);
        if (response.error) {
            yield put(addNewData({ text: data.data.userEmail }));

        } else {
            yield put(errorInOperation(response.message));
        }
    } catch (error) {
        captureException(error, "error");
        yield put(errorInOperation("There is An Error in Add New User!"));
    }
}

function* deleteUser(data) {

    try {
        const response = yield delete_user(data);
        if (response.error) {
            yield put(deleteData(data));

        } else {
            yield put(errorInOperation(response.message));
        }
    } catch (error) {
        captureException(error, "error");
        yield put(errorInOperation("There is An Error in Add New User!"));
    }
}

function* updateUser(data) {

    try {
        const response = yield update_user(data);
        if (response.error) {
            yield put(editData({ value: data.data.userEmail, propt: ["email"] }));

        } else {
            yield put(errorInOperation(response.message));
        }
    } catch (error) {
        captureException(error, "error");
        yield put(errorInOperation("There is An Error in Update User!"));
    }
}

function* editQbankQuestion(data) {
    const editValue = data.value;
    const editKey = data.key;
    const editPropt = data.propt;
    try {
        const deleteChoicesResponse = yield delete_section(data?.key + "/questionChoices");
        if (deleteChoicesResponse == true) {
            const response = yield update_data(editValue, editKey, editPropt, data)
            if (response == true) {
                yield put(editData(data));
            }
        } else {

            yield put(errorInOperation("There is An Error in Edit value!"));
        }

    }
    catch (error) {
        captureException(error, "error");
        yield put(errorInOperation("There is An Error in Edit value!"));
    }

}

function* forgotPassword(data) {
    try {
        const response = yield forgotPasswordOtpSentMethod(data?.email);
        if (!response.error) {
            yield put(forgotPasswordOtpSent(response.data));
        } else {
            yield put(errorInOperation(response.message));
        }

    } catch (error) {
        captureException(error, "error");
        yield put(errorInOperation("There is An Error for sent Otp!"));
    }
}

function* verifyOtpMethod(data) {
    try {
        const response = yield verifyForgotPasswordOtp(data);
        if (!response.error) {
            yield put(verifyOtp('success'));
        } else {
            yield put(errorInOperation(response.message));
        }

    } catch (error) {
        captureException(error, "error");
        yield put(verifyOtp("There is An Error in Verify Otp!"));
    }
}
function* updateUserPassword(data) {
    try {
        const response = yield updatePasswordInAuth(data);
        if (!response.error) {
            yield put(userPasswordUpdateStatus('success'));
        } else {
            yield put(errorInOperation(response.message));
        }

    } catch (error) {
        captureException(error, "error");
        yield put(verifyOtp("There is An Error in Verify Otp!"));
    }
}

function* genrateWithAi(data) {

    try {
        const response = yield axios.post(GET_GPT_RESPONSE, { threadId: "", prompt: data?.prompt, output_mode: data?.output_mode });

        if (response.status === 200 && response.data.error === false) {

            let genratedData = "";
            for (const message of response.data.responseData.reverse()) {
                if (message?.role == "assistant") {

                    genratedData = JSON.parse(message?.content[0]?.text?.value || []);

                }
            }

            yield put(AiGenratedQuestionChoice(genratedData));

        } else {

            yield put(errorInOperation(response?.data?.errorMessage));

        }

    } catch (error) {
        console.log(error);
        captureException(error, "error");
        yield put(errorInOperation("There is An Error in Genrate With Ai!"));
    }
}

function* watcherSaga() {
    yield takeEvery(EDIT_TEXT_AND_SHORT_TEXT, edit)
    yield takeEvery(DELETE_SECTION, deleteSection)
    yield takeEvery(ADD_NEW_AT_ROOT, addNewAtRoot)
    yield takeEvery(SET_ORDER_PAGE, setOrderPage)
    yield takeEvery(ADD_NEW_USER, addNewUser)
    yield takeEvery(DELETE_USER, deleteUser)
    yield takeEvery(UPDATE_USER_INFO, updateUser)
    yield takeEvery(EDIT_QBANK_QUESTION, editQbankQuestion)
    yield takeEvery(FORGOT_PASSWORD_OTP, forgotPassword)
    yield takeEvery("VERIFY_OTP", verifyOtpMethod)
    yield takeEvery("UPDATE_PASSWORD", updateUserPassword)
    yield takeEvery("GENRATE_WITH_AI", genrateWithAi)
}

export default watcherSaga