import React, { useState, useRef } from 'react'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FULL_CONTENT_ACCESS, POLICIES_PAGE_ACCESS, ADD_NEW_USER, UPDATE_USER_INFO } from '../../constant/constant';
import { notify } from '../toasts/ToastDefault';
import { useDispatch } from 'react-redux';

const AddNewUserModel = ({ setAddNewUserModelVisiblityFunction, data, loading, editedData, editvalueIndex, usersData }) => {

    const [user, setUser] = useState({
        userName: (editvalueIndex != "" && editedData != undefined) ? editedData.name : "",
        userEmail: (editvalueIndex != "" && editedData != undefined) ? editedData.email : "",
        userPassword: "",
        userConfirmPassword: "",
        userInstitution: ((editvalueIndex != "") && (editedData != undefined) && (editedData?.roles?.policy == true)) ? Object.keys(usersData[editvalueIndex]?.amionLogin)[0] : "-1",
        userLot: (editvalueIndex != "" && editedData != undefined) ? editedData.lot : "-1",
        [POLICIES_PAGE_ACCESS]: (editvalueIndex != "" && editedData != undefined) ? ((editedData?.roles?.policy != undefined) ? editedData?.roles?.policy : false) : false,
        [FULL_CONTENT_ACCESS]: (editvalueIndex != "" && editedData != undefined) ? ((editedData.roles.content != undefined) ? editedData.roles.content : false) : false,
    })
    const dispatch = useDispatch();

    const formreference = useRef();

    const [instituteVisiblity, setInstituteVisiblity] = useState(user[POLICIES_PAGE_ACCESS]);

    const pgyLevels = [
        'MS1 or MS2',
        'MS3',
        'MS4',
        'PGY1',
        'PGY2',
        'PGY3',
        'PGY4 or above',
        'Fellow',
        'Attending'
    ];

    let name, value;
    function getRecord(event) {
        name = event.target.name;
        value = (event.target.type == "checkbox") ? event.target.checked : event.target.value;
        let usr = { ...user, [name]: value };
        if (name == POLICIES_PAGE_ACCESS) {
            setInstituteVisiblity(!instituteVisiblity);
            usr = { ...usr, userInstitution: "-1" };

        }
        setUser(usr);
    }

    function handleSubmit(event) {
        event.preventDefault();
        const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

        if ((editvalueIndex == "" && user.userName.trim() === "") || user.userEmail.trim() === "" || (editvalueIndex == "" && user.userPassword.trim() === "") || (editvalueIndex == "" && user.userConfirmPassword.trim() === "") || user.userLot.trim() == "-1" || (editvalueIndex == "" && (user[FULL_CONTENT_ACCESS] == false && user[POLICIES_PAGE_ACCESS] == false)) || (instituteVisiblity && user.userInstitution.trim() === "-1")) {

            notify("All Fields Are Required !", "error");

        }
        else if (!user.userEmail.trim().match(regex)) {

            notify("Invalid Email !", "error");

        }
        else if (editvalueIndex == "" && user.userPassword.trim().length < 6) {

            notify("Password Must Be Atleast 6 Characters !", "error");


        } else if ((editvalueIndex == "" && !user.userPassword.trim().match(/^[0-9a-zA-Z@]+$/))) {

            notify("Password Must Be Alphanumeric", "error");


        }
        else if ((editvalueIndex == "" && user.userConfirmPassword.trim() !== user.userPassword.trim())) {

            notify("Password Mismatch !", "error");

        }
        else {

            loading.setLoading(true);
            if (editvalueIndex != "" && editedData != undefined) {

                let superAdminCheck = false;
                if (editedData.roles.superAdmin) {
                    superAdminCheck = true;
                }
                dispatch({ type: UPDATE_USER_INFO, data: user, key: editvalueIndex, isSuperAdmin: superAdminCheck });
            } else {

                dispatch({ type: ADD_NEW_USER, data: user });
            }
            setInstituteVisiblity(false);
            setUser({})
            formreference.current.reset();
            // event.target.reset();
        }
    }

    return (
        <>
            <div className="modal modal-right show d-block" id="exampleModalRight" tabIndex="-1" role="dialog" aria-labelledby="exampleModalRight" style={{ "paddingRight": "17px", "background": "rgba(0,0,0,0.7)" }} aria-modal="true" >
                <div className="modal-dialog" role="document">
                    <div className="modal-content" >
                        <div className="modal-header">
                            <h5 className="modal-title fw-bold">Add User</h5>

                            <button type="button" onClick={() => setAddNewUserModelVisiblityFunction()} className="btn-close"></button>

                        </div>
                        <div className="modal-body">

                            <form onSubmit={(e) => handleSubmit(e)} ref={formreference}  >
                                <Form.Group controlId="name">
                                    <Form.Label> <strong>Name</strong> </Form.Label>
                                    <Form.Control autoFocus type="text" placeholder="Enter Name " name='userName' defaultValue={user.userName} onChange={(e) => getRecord(e)} />
                                </Form.Group>
                                <Form.Group controlId="formBasicEmail" className='my-3' >
                                    <Form.Label> <strong>User Email</strong> </Form.Label>
                                    <Form.Control type="email" placeholder="Enter Email " name='userEmail' defaultValue={user.userEmail} onChange={(e) => getRecord(e)} />
                                </Form.Group>
                                <Form.Group >
                                    <Form.Label><strong>Passcode</strong> </Form.Label>
                                    <Form.Control type="password" placeholder="Enter Passcode" name='userPassword' onChange={(e) => getRecord(e)} />
                                </Form.Group>
                                <Form.Group className='my-3'  >
                                    <Form.Label><strong>Confirm Passcode</strong> </Form.Label>
                                    <Form.Control type="password" placeholder="Re-enter Passcode" name='userConfirmPassword' onChange={(e) => getRecord(e)} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label><strong>Level Of Training</strong> </Form.Label>
                                    <Form.Select name='userLot' onChange={(e) => getRecord(e)}>
                                        <option value="-1">-- Select Level Of Training -- </option>
                                        {
                                            pgyLevels.map((item, index) => {
                                                return <option key={item} selected={user.userLot == item} value={item}>{item}</option>
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className='my-3' >
                                    <Form.Label> <strong>Privileges</strong> </Form.Label>
                                    <Form.Check
                                        type="checkbox"
                                        id="full"
                                        label="Full Content"
                                        name={FULL_CONTENT_ACCESS}
                                        checked={user[FULL_CONTENT_ACCESS]}
                                        onChange={(e) => getRecord(e)}
                                        size="xl"
                                        style={{ fontSize: "1rem" }}
                                    />
                                    <Form.Check
                                        type="checkbox"
                                        id="policy"
                                        label="Policies"
                                        checked={user[POLICIES_PAGE_ACCESS]}
                                        name={POLICIES_PAGE_ACCESS}
                                        style={{ fontSize: "1rem" }}
                                        onChange={(e) => getRecord(e)}
                                        size="xl" />

                                </Form.Group>

                                {
                                    instituteVisiblity &&
                                    <Form.Group className='my-3'>
                                        <Form.Label><strong>Institution</strong> </Form.Label>
                                        <Form.Select aria-label="Default select example" name='userInstitution' onChange={(e) => getRecord(e)}>
                                            <option value="-1">-- Select Institute -- </option>
                                            {
                                                data.map((item, index) => {
                                                    return <option key={index} selected={user.userInstitution == item.amionLogin} value={item.amionLogin}>{item.name}</option>
                                                })
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                }

                                <div className='text-end'>
                                    <Button disabled={loading.isLoading} variant="primary" type="submit">
                                        {loading.isLoading ? 'Submiting…' : 'Submit'}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddNewUserModel
