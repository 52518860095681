import TinyEditorSection from './TinyEditorSection';
import { Editor } from '@tinymce/tinymce-react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EditContentElementPage, ADD_NEW_AT_ROOT } from "../../constant/constant"
import Spinner from 'react-bootstrap/Spinner';
import ToastDefault, { notify } from '../toasts/ToastDefault';
import DeepLinkSection from './DeepLinkSection';
import { storage } from '../../firebaseDb/config/firebase';
import { uploadBytes, ref, getDownloadURL } from "firebase/storage";
import uuid from 'react-uuid';


const AddNewTopModel = ({ modelVisible, linkKey, totalItemsLength, type }) => {

    const [newToggleVisibleEvent, setNewToggleVisibleEvent] = useState(true);
    const fileInput = useRef(null);
    const dispatch = useDispatch()

    const [DeepLinkVisiblity, setDeepLinkVisiblityFn] = useState(false);
    // on add new form submit 
    const [addNew, setAddNewInput] = useState({
        title: "",
        editorBody: "",
        elementType: 1,
        imgUrl: ""

    });

    let name, value;
    const getRecord = (name, value) => {

        setAddNewInput({ ...addNew, [name]: value });

    }

    const handleAddNewSectionFormSubmit = (e) => {
        e.preventDefault();
        if (newToggleVisibleEvent) {
            if (addNew.title.trim() != "" && addNew.editorBody.trim() != "") {

                dispatch({ type: ADD_NEW_AT_ROOT, linkDestination: linkKey, navPush: EditContentElementPage, text: addNew.title, editorBody: addNew.editorBody, elementType: addNew.elementType, collapsibleImage: "", orderInPage: totalItemsLength, imgUrl: "", contentType: type });
                e.target.reset()

            } else {
                notify("All Field Must Be Filled", "error");
            }
        } else {
            if (addNew.title.trim() != "" && addNew.imgUrl != "") {
                const uploadImageTofirebaseStore = image_upload_handler_fun(addNew.imgUrl);
                uploadImageTofirebaseStore.then((res) => {
                    dispatch({ type: ADD_NEW_AT_ROOT, linkDestination: linkKey, navPush: EditContentElementPage, text: addNew.title, editorBody: "", elementType: '3', collapsibleImage: (addNew.elementType == '1') ? true : false, imgUrl: res, orderInPage: totalItemsLength, contentType: type });
                    fileInput.current.value = "";
                }).catch((error) => {
                    notify("Image Upload Error ! \n" + error, "error");
                });

            } else {
                notify("Provide Valid Data !", "error");
            }
        }
    }


    let DeepLinkData = useSelector(state => state.AllData);


    const toggleDeepLinksectionVisiblity = () => {

        setDeepLinkVisiblityFn(!DeepLinkVisiblity);
    }

    const image_upload_handler_fun = (blobInfo, progress) => new Promise((resolve, reject) => {

        const file = (typeof blobInfo.blob === "function") ? blobInfo.blob() : blobInfo;
        const storageRef = ref(storage, '/test_images/' + uuid() + "_" + file.name);

        uploadBytes(storageRef, file).then((snapshot) => {

            getDownloadURL(snapshot.ref).then((downloadURL) => {

                resolve(downloadURL);

            }).catch((error) => {

                reject("Error getting download URL:", error);
                // failure(error);
            });
        }).catch((error) => {

            reject("Error uploading image:", error);
        });


        // resolve('/uploaded/image/path/image.png');

    });
    return (
        <>

            <DeepLinkSection show={DeepLinkVisiblity} onHide={() => setDeepLinkVisiblityFn(false)} data={DeepLinkData.editorContent} toggleDeepLinksectionVisiblity={toggleDeepLinksectionVisiblity} activeEditorIndex={"top_new_model"} />

            <div className="modal fade bd-example-modal-lg show" tabIndex="-1" role="dialog" style={{ "display": "block", "paddingRight": "15px" }} aria-modal="true">
                <div className="modal-dialog modal-lg ">
                    <div className="modal-content bg-light shadow rounded-4" style={{ "border": "1px solid rgba(102,88,155,0.3)" }}>
                        <div className="modal-header">
                            <h5 className="modal-title fw-bold">Add New Section</h5>
                            <button type="button" onClick={() => modelVisible()} className="btn-close"></button>
                        </div>
                        <div className="modal-body h-100">
                            <form onSubmit={(e) => handleAddNewSectionFormSubmit(e)} >
                                <div className="row align-items-center">
                                    <div className="col-6">
                                        <Form.Group className="mt-3" controlId="formBasicEmail">
                                            <Form.Control type="text" className=' mb-3 font-dark' autoFocus placeholder="Enter Title" name='title' onChange={(e) =>
                                                getRecord(e.target.name, e.target.value)} />
                                        </Form.Group>
                                    </div>
                                    <div className="col-6">
                                        <button type='button' className={`btn btn-${(newToggleVisibleEvent) ? "primary" : "info"}`} onClick={() => { setNewToggleVisibleEvent(!newToggleVisibleEvent) }}>  {(newToggleVisibleEvent) ? "Change To Image" : "Change To Editor"}</button>
                                    </div>
                                </div>
                                <nav className="breadcrumb-container d-none d-sm-block d-lg-inline-block bg-light  rounded-2 w-100" aria-label="breadcrumb">
                                    {(newToggleVisibleEvent) ?
                                        <Editor
                                            apiKey='2bzh0pg8ngu6v6bwkpvr6np02lcgot98t9vd1itsinguq92r'
                                            // onInit={(evt, editor) => editorRef.current = editor}
                                            initialValue={""}
                                            onEditorChange={(newValue, editor) => getRecord("editorBody", newValue)}
                                            init={{
                                                height: 500,
                                                images_upload_handler: image_upload_handler_fun,
                                                menubar: false,
                                                toolbar_mode: 'scrolling',
                                                plugins: ["anchor", "autolink", "autoresize", "codesample", "spellchecker", "paste", "image", "file", "imagetools", "lists", "link", "media", "noneditable", "table", "template"],
                                                toolbar: "urldialog  undo redo | fontselect | fontsizeselect | styleselect | bold italic underline strikethrough | justifyleft justifycenter justifyright justifyfull | bullist numlist | outdent indent hr |  link unlink | image | file | sub sup | forecolor backcolor forecolorpicker backcolorpicker|  removeformat formatselect | tablecontrols table row_props cell_props delete_col delete_row col_after col_before row_after row_before split_cells merge_cells | indigoDeepLink | fontsize",
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; overflow-y:scroll !important;}',
                                                setup: (editor) => {

                                                    editor.ui.registry.addButton('urldialog', {
                                                        icon: 'browse',
                                                        text: 'Deep Link',
                                                        onAction: () => {
                                                            // show Deep Link section on click
                                                            toggleDeepLinksectionVisiblity()

                                                        }
                                                    });
                                                    window.addEventListener("message", (event) => {
                                                        if (event.data.cmd == "insertDeepLink_top_new_model") {
                                                            let name = event.data.value.name;
                                                            let pageType = event.data.value.pageType;
                                                            let linkDestination = event.data.value.linkDestination;
                                                            editor.insertContent(
                                                                `[deeplink]${pageType}|${linkDestination}|${name}[/deeplink]`
                                                            );
                                                        }

                                                    });


                                                },
                                            }}
                                        />
                                        :
                                        <div className="card m-3" style={{ width: "15rem" }}>
                                            <input type="file" ref={fileInput} onChange={(e) => getRecord("imgUrl", e.target.files[0])} className="form-control-file" />
                                        </div>
                                    }

                                </nav>
                                <div >
                                    <div className="row align-items-center">
                                        <div className="col-6 ">
                                            <div className="form-group form-check ms-4">
                                                <input style={{ fontSize: "1.2rem" }} className="form-check-input " onChange={(e) => getRecord("elementType", e.target.checked)} type="checkbox" name='isCollapsible' defaultChecked />
                                                <label style={{ fontSize: "1rem" }} className="form-check-label" for="defaultCheck1">
                                                    Collapsible
                                                </label>
                                            </div>
                                        </div>
                                        <div className='text-end col-6'>
                                            <Button variant="primary" className='mt-3' type="submit">
                                                Save Changes
                                            </Button>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AddNewTopModel