import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import loadingImage from '../../assets/images/loading2.gif';
import { ListPage, ContentPage } from '../../constant/constant';
import { useNavigate, useLocation } from "react-router-dom";
import { recursionData, reduxSearchData } from '../../commanFunction';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
// import '../../style/nav/topNav.css';





const DeepLinkSection = (props) => {

    let [reduxData, setReduxData] = useState(props.data);
    const navigate = useNavigate();

    const send = (e) => {
        window.postMessage(
            {
                mceAction: 'execCommand',
                cmd: 'insertDeepLink_' + (props.activeEditorIndex),
                value: {
                    linkDestination: e.target.getAttribute("data-linkdestination"),
                    pageType: e.target.getAttribute("data-pagetype"),
                    name: e.target.getAttribute("data-name")
                }
            },
            origin
        );

        // close the model after clicking on link
        props.toggleDeepLinksectionVisiblity();
        // set state back to page 
        setReduxData(props.data);

    }
    // -----------------navigate to page with key--------------------
    let [previousData, setPreviousData] = useState([]);
    function navigateWithKey(key, pageType) {

        if (pageType === ListPage) {

            let recursionDataResponse = recursionData(props.data, key, "onlyKey")

            if (Object.entries(recursionDataResponse).length > 0) {

                if (reduxData.key != undefined) {

                    setPreviousData([...previousData, reduxData.key]);
                }

                setReduxData(recursionDataResponse)
            }
        }


    }
    function backOnclick() {

        if (previousData.length > 0) {

            let previousDataResponse = recursionData(props.data, (previousData[previousData.length - 1]), "onlyKey")

            if (Object.entries(previousDataResponse).length > 0) {

                setPreviousData(previousData.filter((key) => key !== previousData[previousData.length - 1]));
                setReduxData(previousDataResponse);

            } else {

                setReduxData(props.data);

            }


        } else {
            setReduxData(props.data);

        }
    }
    // search data function
    function setSearch(searchValue) {
        if (searchValue.length > 0) {
            let recursionDataResponse = reduxSearchData(props.data, searchValue)
            if (Object.entries(recursionDataResponse).length > 0) {
                setReduxData(recursionDataResponse)
            } else {

                setReduxData({})
            }
        } else {

            setReduxData(props.data)
        }

    }
    return (
        // <>
        //     {(!error) ?

        //         <div className=" d-flex justify-content-center align-items-center" style={{ "height": "90vh" }}>

        //             <img src={loadingImage} ></img>

        //         </div>

        //         :

        //         (Object.entries(reduxData).length > 0) ?
        //             Object.values(reduxData).map((key, index) => {
        //                 if (key.navPush === ListPage || key.navPush === ContentPage) {
        //                     return (
        //                         <div key={index} className="card d-flex flex-row mb-3 mt-1 " >
        //                             <div className="d-flex flex-grow-1 min-width-zero">
        //                                 <div className="card-body  d-flex flex-row flex-md-row justify-content-between min-width-zero align-items-center">
        //                                     <a className="list-item-heading mb-0 truncate w-40 w-xs-100 " style={{ cursor: 'pointer' }} onClick={() => navigateWithKey(key.key, key.navPush)}>
        //                                         {(key.text) ? key.text : "No Name"}
        //                                     </a>
        //                                     <div className="d-flex align-items-center ">
        //                                         <button className={`badge badge-pill  badge-${(key.navPush === ListPage) ? "success" : "primary"}`} data-linkdestination={key.linkDestination} data-pagetype={key.navPush} data-name={key.text} type='submit' onClick={(e) => send(e)}>Select {key.navPush} Page </button>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     )
        //                 }
        //             })
        //             :
        //             "No Data"

        //     }



        // </>
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    {/* <div className="row"> */}
                    <div className="col-md-3">
                        <Modal.Title id="contained-modal-title-vcenter">
                            <FontAwesomeIcon icon={faArrowLeft} onClick={() => backOnclick()} className='me-2' />
                            Deep Link
                        </Modal.Title>


                    </div>
                    <div className="col-md-6">

                        <div style={{
                            position: "relative",
                            width: "230px",
                            borderRadius: "20px",
                            background: " #f8f8f8"
                        }}>
                            <input placeholder="Search..." style={{
                                border: "initial",
                                outline: "none",
                                background: "0 0",
                                // outline: "initial !important",
                                fontSize: ".8rem",
                                width: "93%",
                                color: "#3a3a3a",
                                padding: "0.4rem 1rem",
                                lineHeight: "2",
                            }}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                            <span className="search-icon" style={{
                                fontSize: "17px",
                                borderRadius: "10px",
                                color: "#d7d7d7",
                                position: "absolute",
                                width: "40px",
                                height: "40px",
                                bottom: "-10px",
                                right: "3px",
                                textAlign: "center",
                                cursor: "pointer"
                            }}>
                                <FontAwesomeIcon icon={faMagnifyingGlass} />
                            </span>
                        </div>
                        {/* <input className="form-control w-75" type="search" onChange={(e) => setSearch(e.target.value)} placeholder="Search" aria-label="Search" /> */}
                    </div>
                    {/* </div> */}
                </Modal.Header>
                <Modal.Body style={{ height: "70vh", overflow: "auto" }}>

                    {

                        (reduxData && Object.entries(reduxData).length > 0) ?
                            Object.values(reduxData).map((key, index) => {
                                if (key.navPush == ListPage || key.navPush == ContentPage) {
                                    return (
                                        <div key={index} className="card d-flex flex-row mb-3 mt-1 " >
                                            <div className="d-flex flex-grow-1 min-width-zero">
                                                <div className="card-body  d-flex flex-row flex-md-row justify-content-between min-width-zero align-items-center">
                                                    <a className="list-item-heading mb-0 truncate w-40 w-xs-100 "
                                                     style={{ cursor: 'pointer' }} 
                                                    onClick={() => navigateWithKey(key.key, key.navPush)}>
                                                        {(key.text) ? key.text : "No Name"}
                                                    </a>
                                                    <div className="d-flex align-items-center ">
                                                        <button className={`badge badge-pill  badge-${(key.navPush === ContentPage) ? "success" : "primary"}`} data-linkdestination={key.linkDestination} data-pagetype={key.navPush} data-name={key.text} type='submit' onClick={(e) => send(e, index)} >Select {key.navPush} Page </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                            :
                            <div key={1} className="card d-flex flex-row mb-3 mt-1 " >
                                <div className="d-flex flex-grow-1 min-width-zero">
                                    <div className="card-body  d-flex flex-row flex-md-row justify-content-center min-width-zero align-items-center">
                                        <h1> No Record Found</h1>
                                    </div>
                                </div>
                            </div>
                    }
                </Modal.Body>
            </Modal></>
    )
}

export default DeepLinkSection
